import React from 'react'
import style from "./AboutComp.module.css";
import imgAbout from "../../media/img/Opcion 2.png";
import { useNavigate } from 'react-router-dom';

const AboutComp = ({ language, translations }) => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate('/about'); // Cambia '/about' por la ruta a la que quieras navegar
  };
  return (
    <div className={style.aboutContainer} id='about'>
        <div><h1>{translations[language].homeTituloTexto1}</h1>
        <p>{translations[language].homeTexto1}</p>
        <div className={style.divButton} onClick={handleButtonClick}>{translations[language].homeBotonTexto1}</div></div>
        <img src={imgAbout}/>
    </div>
  )
}

export default AboutComp