import React from 'react';
import './Whatsapp.css'
import { IconBrandWhatsapp } from '@tabler/icons-react';

const Whatsapp = ({ language, toggleLanguage, translations }) => {
    return (
        <div className='wsp'>
                <div className='wpHover'></div>
                <a href="https://wa.me/5491128451864"target="_blank" >
                <IconBrandWhatsapp className='whatsapp'/></a>
        </div>
    );
};

export default Whatsapp;