import React from 'react';
import AudioBookItem from '../../components/AudioBookItem/AudioBookItem';
import style from './DubbingSection.module.css';
import mediaItems from '../../jsons/dubbing.json'


const DubbingSection = () => {

  return (
    <div className={style.DubbingSectionContainer}>
      {mediaItems.map((item, index) => (
        <AudioBookItem
          key={index}
          type={item.type}
          audioImg={item.image}
          audio={item.source}
        />
      ))}
    </div>
  );
};

export default DubbingSection;
