import React, { useEffect } from 'react';
import style from './Clients.module.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Importar CSS de Bootstrap
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Importar JS de Bootstrap
import logo1 from './media/Clientes/BIC logo.png';
import logo2 from './media/Clientes/Buscapina-_opcion-2-sin-fondo-nose-si-sirve_.png';
import logo3 from './media/Clientes/CHANNEL.jpg';
import logo4 from './media/Clientes/Chainlink logo.jpg';
import logo5 from './media/Clientes/Disney plus.png';
import logo6 from './media/Clientes/Editorial Planeta.png';
import logo7 from './media/Clientes/Intermountain Health.jpg';
import logo8 from './media/Clientes/MAX.png';
import logo9 from './media/Clientes/Netflix.png';
import logo10 from './media/Clientes/PALADIN.png';
import logo11 from './media/Clientes/PHMG.png';
import logo12 from './media/Clientes/Penguin.jpg';
import logo13 from './media/Clientes/Plenitud.png';
import logo14 from './media/Clientes/ReadingU.png';
import logo15 from './media/Clientes/Spark Driver.png';
import logo16 from './media/Clientes/Spotify.png';
import logo17 from './media/Clientes/Univeristy of South Carolina.png';
import logo18 from './media/Clientes/Volkswagen_logo.png';
import logo19 from './media/Clientes/Zorro.png';
import logo20 from './media/Clientes/CAPENT.png';
import logo21 from './media/Clientes/Education.png';

const Clients = ({ language, translations }) => {
  useEffect(() => {
    const carouselElement = document.getElementById('carouselExampleSlidesOnly');
    const carousel = new window.bootstrap.Carousel(carouselElement, {
      interval: 3000, // Cambia el intervalo aquí
      ride: 'carousel',
    });

    return () => {
      carousel.dispose(); // Limpia la instancia del carrusel al desmontar el componente
    };
  }, []);

  return (
    <div className={style.clientsContainerAll}>
      <h1>
      {translations[language].clientesTitulo}
      </h1>
      <div id="carouselExampleSlidesOnly" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className={style.carouselDiv}>
              <img src={logo1} className={style.logoCliente}/>
              <img src={logo2} className={style.logoCliente}/>
              <img src={logo3} className={style.logoCliente}/>
              <img src={logo4} className={style.logoCliente}/>
              <img src={logo5} className={style.logoCliente}/>
              <img src={logo6} className={style.logoCliente}/>
              <img src={logo7} className={style.logoCliente}/>
            </div>
          </div>
          <div className="carousel-item">
            <div className={style.carouselDiv}>
              <img src={logo8} className={style.logoCliente}/>
              <img src={logo9} className={style.logoCliente}/>
              <img src={logo10} className={style.logoCliente}/>
              <img src={logo11} className={style.logoCliente}/>
              <img src={logo12} className={style.logoCliente}/>
              <img src={logo13} className={style.logoCliente}/>
              <img src={logo14} className={style.logoCliente}/>
            </div>
          </div>
          <div className="carousel-item">
            <div className={style.carouselDiv}>
              <img src={logo15} className={style.logoCliente}/>
              <img src={logo16} className={style.logoCliente}/>
              <img src={logo17} className={style.logoCliente}/>
              <img src={logo18} className={style.logoCliente}/>
              <img src={logo19} className={style.logoCliente}/>
              <img src={logo20} className={style.logoCliente}/>
              <img src={logo21} className={style.logoCliente}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Clients;
