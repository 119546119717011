import React, { useEffect, useState } from "react";
import style from "./OurVoices.module.css";
import AudioBookItem from '../AudioBookItem/AudioBookItem';
import { useNavigate } from 'react-router-dom';
import { IconMicrophone } from '@tabler/icons-react';

// Aquí importamos el JSON que contiene los datos de los items
import mediaItems from '../../jsons/voiceRecording.json';

const OurVoices = ({ language, translations }) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/jobs'); // Cambia '/about' por la ruta a la que quieras navegar
  };

  useEffect(() => {
    let timeout;
    const handleScroll = () => {
      // Cuando detectas scroll, cambia el estado
      setIsScrolling(true);

      // Detén el timeout si hay desplazamiento
      clearTimeout(timeout);

      // Configura un nuevo timeout para determinar si el usuario deja de scrollear
      timeout = setTimeout(() => {
        setIsScrolling(false); // Cambia el estado a falso si no hay desplazamiento
      }, 200); // Cambia este valor si deseas ajustar el tiempo sin scroll
    };

    // Añade el listener de scroll
    window.addEventListener("scroll", handleScroll);

    // Limpia el listener y el timeout cuando se desmonta el componente
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className={style.ourVoicesContainer} id='voices'>
      <div className={style.divVoice}>
        <h1>{translations[language].nuestrasVocesTitulo}</h1>
        <div className={style.divButton} onClick={handleButtonClick}>
          {translations[language].nuestrasVocesBoton}
        </div>
      </div>
      
      <div className={style.divVoice}>
        <div className={style.carouselContainer}>
          <div id="carouselExampleFade" className="carousel slide carousel-fade">
            <div className="carousel-inner">
              {/* Recorremos el archivo JSON y renderizamos cada item como un AudioBookItem */}
              {mediaItems.map((item, index) => (
                <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                  <div className={style.carouselDiv}>
                    <AudioBookItem
                      type={item.type}
                      audioImg={item.image}
                      audio={item.source}
                      className={style.carouselItem}
                    />
                  </div>
                </div>
              ))}
            </div>
            
            {/* Botones para navegar en el carrusel */}
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>

      <div className={style.dec}>
        <IconMicrophone className={style.decMic} />
      </div>

      <div className={isScrolling ? `${style.dec2d}` : `${style.dec2dNone}`}>
        <div className={style.dec2}></div>
        <div className={style.dec2}></div>
        <div className={style.dec2}></div>
        <div className={style.dec2}></div>
      </div>
    </div>
  );
};

export default OurVoices;
