import React from 'react'
import style from "./Podcast.module.css";
import imgPodcast from '../../media/img/podcast.png'

const Podcast = ({ language, translations }) => {
  return (
    <div className={style.podcastContainer}>
        <img src={imgPodcast} alt=''/>
        <div><p>{translations[language].homePodcastSub}</p><h1>{translations[language].homePodcastTitulo}</h1>
        <div className={style.divButton}>{translations[language].homePodcastBoton}</div></div>
    </div>
  )
}

export default Podcast