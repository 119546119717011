import React, { useRef, useEffect } from 'react'
import  style from "./Cover.module.css";

const Cover = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);
  
  return (
    <div className={style.cover} id='home'>
        <video
        ref={videoRef}
        autoplay
        muted
        loop
        controls={true} // Si no quieres mostrar los controles
        className={style.aboutVideo} // Ajuste de tamaño opcional
      ><source src="https://www.dropbox.com/scl/fi/2kj7ekqq5j3x753tn2wun/HOME-VIDEO-WEB.mp4?rlkey=vj3qk39chn89gt9569i42yrs0&st=olvbolmk&raw=1" type="video/mp4" /></video>
    </div>
  )
}

export default Cover