import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    // Desplaza el scroll al inicio cuando cambia la ruta
    window.scrollTo(0, 0);
  }, [location]);

  return null; // Este componente no renderiza nada en la UI
};

export default ScrollToTop;
