import React from 'react';
import style from './Contact.module.css';
import img from '../../media/img/logo blanco sin fondo.png';
import { IconBrandLinkedin, IconBrandInstagram, IconBrandTiktok, IconBrandYoutube } from '@tabler/icons-react';

const Contact = ({ language, translations }) => {
  return (
    <div className={style.contactContainerAll}>
        <div className={style.divLeft}>
          <div className={style.divLogoContact}>
            <div><img src={img}/></div>
            <div> 
            <h1>Etervoz</h1>
            <h2>{translations[language].contactoLogo}</h2>
            </div>
          </div>
          <div className={style.divRedesContact}>
            <h2>{translations[language].contactoText}
            </h2>
            <div className={style.redesDiv}>
              <div><a href='https://www.linkedin.com/in/ezealvarez/' target='_blank'><IconBrandLinkedin className={style.redIcono}/></a></div>
              <div><a href='https://www.instagram.com/etervoz/' target='_blank'><IconBrandInstagram className={style.redIcono}/></a></div>
              <div><a href='https://www.tiktok.com/@etervoz' target='_blank'><IconBrandTiktok className={style.redIcono}/></a></div>
              <div><a href='https://www.youtube.com/@etervozproducciones3191' target='_blank'><IconBrandYoutube className={style.redIcono}/></a></div>
            </div>
          </div>
        </div>
        <div className={style.divRight}>
        <h2>{translations[language].contactoFormTitulo}</h2>
    <form action="/submit-form" method="POST">
        <input type="text" id="name" name="name" placeholder={translations[language].contactoFormNombre} required/>
        <input type="email" id="email" name="email" placeholder={translations[language].contactoFormEmail} required/>
        <textarea id="message" name="message" rows="4" placeholder={translations[language].contactoFormMensaje} required></textarea>
        <button type="submit">{translations[language].contactoFormBoton} </button>
        </form>
        </div>
    </div>
  )
}

export default Contact