import React from 'react';
import { Link } from 'react-router-dom'; 
import style from './Articles.module.css';

const Articles = ({ title, image, link }) => {
  return (
    <div>
      <Link to={link} target='_blank' className={style.articuloDiv}>
        <img src={image} alt={title} />
        <h1>{title}</h1>
      </Link>
    </div>
  );
};

export default Articles;
