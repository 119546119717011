import React from 'react';
import style from "./AudioBooks.module.css";
import AudioBookItem from '../AudioBookItem/AudioBookItem';
import audioBooksData from '../../jsons/audioEditing.json'; // Importa el JSON

const AudioBooks = ({ language, translations }) => {
  // Función para dividir los datos en grupos de 3
  const groupInThrees = (arr) => {
    const result = [];
    for (let i = 0; i < arr.length; i += 3) {
      result.push(arr.slice(i, i + 3));
    }
    return result;
  };

  const groupedItems = groupInThrees(audioBooksData); // Agrupamos en grupos de 3

  return (
    <div className={style.audioBooksContainer} id='edit'>
      <h1>{translations[language].homeCarouselAudio}</h1>
      <div className={style.carouselContainer}>
        <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            {/* Iteramos sobre los grupos de 3 audiolibros */}
            {groupedItems.map((group, index) => (
              <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                <div className={style.carouselDiv}>
                  {/* Renderizamos los AudioBookItem para cada grupo */}
                  {group.map((item, idx) => (
                    <AudioBookItem
                      key={idx}
                      type={item.type}
                      audioImg={item.image}
                      audio={item.source}
                      className={style.carouselItem}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>

          {/* Botones de control para el carrusel */}
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AudioBooks;
