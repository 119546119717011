import React from 'react';
import AudioBookItem from '../../components/AudioBookItem/AudioBookItem';
import style from './AudiobooksSection.module.css';
import mediaItems from '../../jsons/audiobooks.json'


const AudiobooksSection = () => {

  return (
    <div className={style.AudiobooksSectionContainer}>
      {mediaItems.map((item, index) => (
        <AudioBookItem
          key={index}
          type={item.type}
          audioImg={item.image}
          audio={item.source}
        />
      ))}
    </div>
  );
};

export default AudiobooksSection;
