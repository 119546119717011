import React from 'react';
import style from './LoadLogo.module.css';
import img from '../../media/img/LOGO FONDO BLANCO 2.png'

const LoadLogo = () => {
  return (
    <div className={style.loadContainer}>
        <div className={style.soundContainer}>
        <div className={style.soundBarDiv}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div className={style.soundBarDiv}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div className={style.soundBarDiv}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div className={style.soundBarDiv}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        
    </div>
    <img src={img}/>
    </div>
  )
}

export default LoadLogo