  import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom'; 
import NavBar from '../../components/NavBar/NavBar';
import AudioBookItem from '../../components/AudioBookItem/AudioBookItem';
import LoadLogo from '../../components/LoadLogo/LoadLogo';
import style from './Services.module.css';
import Banner from '../../components/Banner/Banner';
import OurVoices from '../../components/OutVoices/OurVoices';
import Contact from '../../components/Contact/Contact';
import { IconVideo, IconMicrophone, IconDeviceAirpods, IconSchool, IconVolume, IconChartBar } from '@tabler/icons-react'; 
import img1 from './media/img/1.png';
import img2 from './media/img/2.png';
import img3 from './media/img/3.png';
import img4 from './media/img/4.png';
import Whatsapp from '../../components/Whatsapp/Whatsapp';



const Services = ({ language, toggleLanguage, translations }) => {
    const [exit, setExit] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick= (link)=>{
      if(location.pathname !== link){
      setExit(true);
      setTimeout(() => {
          navigate(link);
        }, 800);
      }else{
          navigate(link);
      }
  }

  return (
    <div className={style.servicesAllContainer}>
        <div className='page-transition'><div className='loadLogo'><LoadLogo/></div></div>
        <div className={`${exit === true ? 'transiition-exit' : ''} `}></div>
        <NavBar language={language} toggleLanguage={toggleLanguage} translations={translations} />
        <div className={style.servicesCover}>
        <Whatsapp language={language} translations={translations}/>        
          <h1>{translations[language].serviciosTitulo1}</h1>        
        
        <div className={style.imagenesCover}>
            <img src={img1}/>
            <img src={img2}/>
            <img src={img3}/>
            <img src={img4}/>
          </div>
          </div>
          <Banner language={language} toggleLanguage={toggleLanguage} translations={translations} />
          <div className={style.serviceSectionTitle}><h1>{translations[language].serviciosTitulo2}</h1>
          <div className={style.servicesSection}>
            <div className={style.serviceItem}><IconMicrophone className={`${style.serviceItemIcon} ${style.r}`}/><h1>{translations[language].tituloServicio1}</h1><p>{translations[language].textoServicio1}</p></div>
            <div className={style.serviceItem}><IconDeviceAirpods className={`${style.serviceItemIcon} ${style.c}`}/><h1>{translations[language].tituloServicio2}</h1><p>{translations[language].textoServicio2}</p></div>
            <div className={style.serviceItem}><IconSchool className={`${style.serviceItemIcon} ${style.v}`}/><h1>{translations[language].tituloServicio3}</h1><p>{translations[language].textoServicio3}</p></div>
            <div className={style.serviceItem}><IconChartBar className={`${style.serviceItemIcon} ${style.b}`}/><h1>{translations[language].tituloServicio4}</h1><p>{translations[language].textoServicio4}</p></div>
            <div className={style.serviceItem}><IconVolume className={`${style.serviceItemIcon} ${style.n}`}/><h1>{translations[language].tituloServicio5}</h1><p>{translations[language].textoServicio5}</p></div>
            <div className={style.serviceItem}><IconVideo className={`${style.serviceItemIcon} ${style.a}`}/><h1>{translations[language].tituloServicio6}</h1><p>{translations[language].textoServicio6}</p></div>
          </div></div>

          <Banner language={language} toggleLanguage={toggleLanguage} translations={translations} />
          <OurVoices language={language} toggleLanguage={toggleLanguage} translations={translations} />
          <Contact language={language} toggleLanguage={toggleLanguage} translations={translations} />
    </div>
  )
}

export default Services